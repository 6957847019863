"use strict";
'kiwi public';
/** @module */

var _typeof3 = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = chathistoryMiddleware;

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * Adds the CHATHISTORY IRCv3 spec to irc-framework
 */
function chathistoryMiddleware() {
  return function middleware(client, rawEvents, parsedEvents) {
    client.requestCap('draft/chathistory');
    addFunctionsToClient(client);
    parsedEvents.use(theMiddleware);
  };

  function theMiddleware(command, event, client, next) {
    if (command.toLowerCase() === 'batch end chathistory' && client.chathistory) {
      var target = event.params[0];
      client.chathistory.batchCallbacks.resolve(target, event);
    }

    if (command.toLowerCase() === 'fail' && event.params[0].toLowerCase() === 'chathistory') {
      // FAIL CHATHISTORY MESSAGE_ERROR the_given_command #target :Messages could not be ...
      if (event.params[1].toLowerCase() === 'message_error') {
        client.chathistory.batchCallbacks.resolve(event.params[3]);
      }
    }

    next();
  }
}

function addFunctionsToClient(client) {
  var history = client.chathistory = {};
  history.batchCallbacks = {
    callbacks: Object.create(null),
    add: function add(target, cb) {
      this.callbacks[target.toLowerCase()] = this.callbacks[target.toLowerCase()] || [];
      this.callbacks[target.toLowerCase()].push(cb);
    },
    resolve: function resolve(target, value) {
      var cbs = this.callbacks[target.toLowerCase()] || [];
      delete this.callbacks[target.toLowerCase()];
      cbs.forEach(function (cb) {
        return cb(value);
      });
    },
    targetCallbacks: function targetCallbacks(target) {
      return this.callbacks[target.toLowerCase()];
    }
  };

  history.isSupported = function () {
    return !!client.network.supports('draft/chathistory');
  };

  history.before = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }

      client.raw('CHATHISTORY', 'BEFORE', target, messageReference(dateOrTime), '50');
      history.batchCallbacks.add(target, resolve);
    });
  };

  history.after = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }

      client.raw('CHATHISTORY', 'AFTER', target, messageReference(dateOrTime), '50');
      history.batchCallbacks.add(target, resolve);
    });
  };

  history.latest = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }

      client.raw('CHATHISTORY', 'LATEST', target, messageReference(dateOrTime), '50');
      history.batchCallbacks.add(target, resolve);
    });
  };

  history.around = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }

      client.raw('CHATHISTORY', 'AROUND', target, messageReference(dateOrTime), '50');
      history.batchCallbacks.add(target, resolve);
    });
  };

  history.between = function (target, fromDateOrTime, toDateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }

      var fromRef = messageReference(fromDateOrTime);
      var toRef = messageReference(toDateOrTime);
      client.raw('CHATHISTORY', 'BETWEEN', target, fromRef, toRef, 50);
      history.batchCallbacks.add(target, resolve);
    });
  };

  function messageReference(inp) {
    if ((0, _typeof2.default)(inp) === 'object') {
      return 'timestamp=' + Misc.dateIso(inp);
    }

    if (inp === '*') {
      return '*';
    }

    return 'msgid=' + inp;
  }
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["ChathistoryMiddleware"]
window._kiwi_exports.libs.ChathistoryMiddleware = exports.default ? exports.default : exports;
