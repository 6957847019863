var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resizeobserver",rawName:"v-resizeobserver",value:(_vm.onListResize),expression:"onListResize"}],key:'messagelist-' + _vm.buffer.name,staticClass:"kiwi-messagelist",class:{'kiwi-messagelist--smoothscroll': _vm.smooth_scroll},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onListClick.apply(null, arguments)}}},[_c('div',{directives:[{name:"resizeobserver",rawName:"v-resizeobserver",value:(_vm.onListResize),expression:"onListResize"}]},[(_vm.shouldShowChathistoryTools)?_c('div',{staticClass:"kiwi-messagelist-scrollback"},[(!_vm.buffer.flag('is_requesting_chathistory'))?_c('a',{staticClass:"u-link",on:{"click":function($event){return _vm.buffer.requestScrollback()}}},[_vm._v(" "+_vm._s(_vm.$t('messages_load'))+" ")]):_c('a',{staticClass:"u-link"},[_vm._v("...")])]):_vm._e(),_c('transition-group',{attrs:{"tag":"div"}},[_vm._l((_vm.filteredMessagesGroupedDay),function(day){return [(_vm.filteredMessagesGroupedDay.length > 1 && day.messages.length > 0)?_c('div',{key:'msgdatemarker' + day.dayNum,staticClass:"kiwi-messagelist-seperator"},[_c('span',[_vm._v(_vm._s((new Date(day.messages[0].time)).toDateString()))])]):_vm._e(),_c('transition-group',{key:day.dayNum,attrs:{"tag":"div"}},[_vm._l((day.messages),function(message){return [(_vm.shouldShowUnreadMarker(message))?_c('div',{key:'msgunreadmarker' + message.id,staticClass:"kiwi-messagelist-seperator"},[_c('span',[_vm._v(_vm._s(_vm.$t('unread_messages')))])]):_vm._e(),_c('div',{key:'msg' + message.id,class:[
                                'kiwi-messagelist-item',
                                _vm.selectedMessages[message.id] ?
                                    'kiwi-messagelist-item--selected' :
                                    ''
                            ]},[(message.render() &&
                                    message.template &&
                                    message.template.$el)?_c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:(message.template.$el),expression:"message.template.$el"}]}):(_vm.listType === 'modern')?_c('message-list-message-modern',{attrs:{"message":message,"idx":_vm.filteredMessages.indexOf(message),"ml":_vm.thisMl}}):(_vm.listType === 'inline')?_c('message-list-message-inline',{attrs:{"message":message,"idx":_vm.filteredMessages.indexOf(message),"ml":_vm.thisMl}}):(_vm.listType === 'compact')?_c('message-list-message-compact',{attrs:{"message":message,"idx":_vm.filteredMessages.indexOf(message),"ml":_vm.thisMl}}):_vm._e()],1)]})],2)]})],2),_c('transition',{attrs:{"name":"kiwi-messagelist-joinloadertrans"}},[(_vm.shouldShowJoiningLoader)?_c('div',{staticClass:"kiwi-messagelist-joinloader"},[_c('LoadingAnimation')],1):_vm._e()]),(_vm.shouldRequestChannelKey)?_c('buffer-key',{attrs:{"buffer":_vm.buffer,"network":_vm.buffer.getNetwork()}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }