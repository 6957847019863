"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Logger = _interopRequireDefault(require("@/libs/Logger"));

var _NicklistUser = _interopRequireDefault(require("./NicklistUser"));

var log = _Logger.default.namespace('Nicklist'); // This provides a better sort for numbered nicks but does not work on ios9


var intlCollator = null;

if (global.Intl) {
  intlCollator = new Intl.Collator({}, {
    numeric: true
  });
} // Hot function, so it's here for easier caching


function strCompare(a, b) {
  if (intlCollator) {
    return intlCollator.compare(a, b);
  }

  if (a === b) {
    return 0;
  }

  return a > b ? 1 : -1;
}

var _default = {
  components: {
    NicklistUser: _NicklistUser.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {
      userbox_user: null,
      user_filter: '',
      filter_visible: false,
      self: this
    };
  },
  computed: {
    shouldShowAvatars: function shouldShowAvatars() {
      return this.buffer.setting('nicklist_avatars');
    },
    sortedUsers: function sortedUsers() {
      var _this = this;

      // Get a list of network prefixes and give them a rank number
      var netPrefixes = this.network.ircClient.network.options.PREFIX;
      var prefixOrders = Object.create(null);
      netPrefixes.forEach(function (prefix, idx) {
        prefixOrders[prefix.mode] = idx;
      }); // A few things here:
      // * Since vuejs will sort in-place and update views when .sort is called
      //   on an array, clone it first so that we have a plain array to sort
      // * Keep a map of lowercased nicks to we don't need to call .toLowerCase()
      //   on each one all the time. This is a hot function!

      var nickMap = Object.create(null);
      var users = [];
      var bufferUsers = this.buffer.users;
      var nickFilter = this.user_filter.toLowerCase();
      /* eslint-disable guard-for-in, no-restricted-syntax */

      for (var lowercaseNick in bufferUsers) {
        var user = bufferUsers[lowercaseNick];
        nickMap[user.nick] = lowercaseNick;

        if (!nickFilter || lowercaseNick.indexOf(nickFilter) !== -1) {
          users.push(user);
        }
      }

      var bufferId = this.buffer.id;
      return users.sort(function (a, b) {
        var bufferA = a.buffers[bufferId];
        var bufferB = b.buffers[bufferId];

        if (!bufferA) {
          var msg = 'Nicklist.sortedUsers() User A does not have the buffer in its list!';
          log.error(msg, a.nick, a.buffers);
          return -1;
        }

        if (!bufferB) {
          var _msg = 'Nicklist.sortedUsers() User B does not have the buffer in its list!';
          log.error(_msg, b.nick, b.buffers);
          return 1;
        }

        var modesA = bufferA.modes;
        var modesB = bufferB.modes; // Neither user has a prefix, compare text

        if (modesA.length === 0 && modesB.length === 0) {
          // Compare away status
          if (_this.$state.setting('nicklistGroupAway')) {
            if (a.away && !b.away) {
              return 1;
            }

            if (!a.away && b.away) {
              return -1;
            }
          }

          return strCompare(nickMap[a.nick], nickMap[b.nick]);
        } // Compare via prefixes..


        if (modesA.length > 0 && modesB.length === 0) {
          return -1;
        }

        if (modesA.length === 0 && modesB.length > 0) {
          return 1;
        } // Both users have a prefix so find the highest ranking one


        var aP = prefixOrders[_this.buffer.userMode(a)];

        var bP = prefixOrders[_this.buffer.userMode(b)];

        if (aP > bP) {
          return 1;
        } else if (aP < bP) {
          return -1;
        } // Prefixes are the same, compare away status


        if (_this.$state.setting('nicklistGroupAway')) {
          if (a.away && !b.away) {
            return 1;
          }

          if (!a.away && b.away) {
            return -1;
          }
        } // Prefixes are the same, resort to comparing text


        return strCompare(nickMap[a.nick], nickMap[b.nick]);
      });
    },
    useColouredNicks: function useColouredNicks() {
      return this.buffer.setting('coloured_nicklist');
    }
  },
  methods: {
    userModePrefix: function userModePrefix(user) {
      return this.buffer.userModePrefix(user);
    },
    userMode: function userMode(user) {
      return this.buffer.userMode(user);
    },
    openQuery: function openQuery(user) {
      var buffer = this.$state.addBuffer(this.buffer.networkid, user.nick);
      this.$state.setActiveBuffer(buffer.networkid, buffer.name);

      if (this.$state.ui.is_narrow) {
        this.sidebarState.close();
      }
    },
    openUserbox: function openUserbox(user) {
      this.$state.$emit('userbox.show', user, {
        buffer: this.buffer
      });
    },
    toggleUserFilter: function toggleUserFilter() {
      var _this2 = this;

      this.filter_visible = !this.filter_visible;

      if (this.filter_visible) {
        this.$nextTick(function () {
          return _this2.$refs.user_filter.focus();
        });
      } else {
        this.user_filter = '';
      }
    },
    onFilterBlur: function onFilterBlur() {
      if (!this.user_filter) {
        this.filter_visible = false;
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Nicklist"]
window._kiwi_exports.components.Nicklist = exports.default ? exports.default : exports;
