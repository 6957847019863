"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var Vue = require('vue');

var _default = Vue.component('input-confirm', {
  props: ['label', 'flipConnotation'],
  data: function data() {
    return {
      state: 'pre'
    };
  },
  computed: {
    connoteCss: function connoteCss() {
      return {
        yes: this.flipConnotation ? 'warning' : 'primary',
        no: this.flipConnotation ? 'primary' : 'warning'
      };
    }
  },
  created: function created() {
    if (!this.$slots.default) {
      this.state = 'prompt';
    }
  },
  methods: {
    prompt: function prompt() {
      this.state = 'prompt';
    },
    complete: function complete(val) {
      this.$emit('submit', val);
      this.$emit(val ? 'ok' : 'cancel');
      this.state = 'pre';
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["InputConfirm"]
window._kiwi_exports.components.utils.InputConfirm = exports.default ? exports.default : exports;
