"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extbanAccount = extbanAccount;

function extbanAccount(network) {
  // Eg. InspIRCd-2.0
  // Eg. UnrealIRCd-4.0.17
  // Eg. ircd-seven-1.1.7
  // Eg. u2.10.12.10+snircd(1.3.4a)
  var ircdType = network.ircd.toLowerCase(); // Eg. ~,qjncrRa
  // Eg. ,qjncrRa

  var extban = network.ircClient.network.supports('EXTBAN') || '';

  if (!extban) {
    return '';
  }

  var prefix = extban.split(',')[0];
  var type = 'a'; // https://docs.inspircd.org/3/modules/services_account/#extended-bans

  if (ircdType.indexOf('inspircd') > -1) {
    type = 'R';
  }

  return prefix + type;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["helpers"]) window._kiwi_exports["helpers"] = {};
window._kiwi_exports["helpers"]["IrcdDiffs"]
window._kiwi_exports.helpers.IrcdDiffs = exports.default ? exports.default : exports;
