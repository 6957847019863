var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-statebrowser-channel-wrapper"},[_c('div',{staticClass:"kiwi-statebrowser-channel",class:{
            'kiwi-statebrowser-channel-active': _vm.isActiveBuffer(),
            'kiwi-statebrowser-channel-notjoined': _vm.buffer.isChannel() &&
                !_vm.buffer.joined
        },attrs:{"data-name":_vm.buffer.name.toLowerCase()}},[_c('div',{staticClass:"kiwi-statebrowser-channel-name",on:{"click":function($event){return _vm.$emit('selected')}}},[(_vm.buffer.isQuery() && _vm.awayNotifySupported())?_c('away-status-indicator',{attrs:{"network":_vm.network,"user":_vm.network.userByName(_vm.buffer.name)}}):_vm._e(),_vm._v(_vm._s(_vm.buffer.name)+" ")],1),_c('div',{staticClass:"kiwi-statebrowser-buffer-actions"},[_c('div',{staticClass:"kiwi-statebrowser-channel-labels"},[(_vm.buffer.flags.unread && _vm.showMessageCounts(_vm.buffer))?_c('div',{staticClass:"kiwi-statebrowser-channel-label",class:[
                        _vm.buffer.flags.highlight ?
                            'kiwi-statebrowser-channel-label--highlight' :
                            ''
                    ]},[_vm._v(" "+_vm._s(_vm.buffer.flags.unread > 999 ? "999+": _vm.buffer.flags.unread)+" ")]):_vm._e()]),_c('div',{staticClass:"kiwi-statebrowser-channel-leave",on:{"click":function($event){return _vm.maybePromptClose()}}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])])]),_c('transition-expand',[(_vm.showPromptClose)?_c('div',{staticClass:"kiwi-statebrowser-prompt-close"},[_c('span',[_vm._v(_vm._s(_vm.buffer.isChannel() ? _vm.$t('prompt_leave_channel') : _vm.$t('prompt_close_query')))]),_c('input-confirm',{attrs:{"flip-connotation":true},on:{"ok":function($event){return _vm.closeBuffer()},"submit":function($event){return _vm.maybePromptClose()}}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }