"use strict";
'kiwi public';
/* eslint-disable */
// command descriptions can be either a static string or a translation key prefixed with locale_id_

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  command: 'msg',
  alias: [],
  description: 'locale_id_command_msg'
}, {
  command: 'action',
  alias: [],
  description: 'locale_id_command_action'
}, {
  command: 'join',
  alias: [],
  description: 'locale_id_command_join'
}, {
  command: 'part',
  alias: [],
  description: 'locale_id_command_part'
}, {
  command: 'cycle',
  alias: [],
  description: 'locale_id_command_cycle'
}, {
  command: 'nick',
  alias: [],
  description: 'locale_id_command_nick'
}, {
  command: 'query',
  alias: [],
  description: 'locale_id_command_query'
}, {
  command: 'invite',
  alias: [],
  description: 'locale_id_command_invite'
}, {
  command: 'topic',
  alias: [],
  description: 'locale_id_command_topic'
}, {
  command: 'notice',
  alias: [],
  description: 'locale_id_command_notice'
}, {
  command: 'quote',
  alias: [],
  description: 'locale_id_command_quote'
}, {
  command: 'kick',
  alias: [],
  description: 'locale_id_command_kick'
}, {
  command: 'clear',
  alias: [],
  description: 'locale_id_command_clear'
}, {
  command: 'ctcp',
  alias: [],
  description: 'locale_id_command_ctcp'
}, {
  command: 'quit',
  alias: [],
  description: 'locale_id_command_quit'
}, {
  command: 'server',
  alias: [],
  description: 'locale_id_command_server'
}, {
  command: 'whois',
  alias: [],
  description: 'locale_id_command_whois'
}, {
  command: 'whowas',
  alias: [],
  description: 'locale_id_command_whowas'
}, {
  command: 'away',
  alias: [],
  description: 'locale_id_command_away'
}, {
  command: 'back',
  alias: ['active'],
  description: 'locale_id_command_back'
}, {
  command: 'encoding',
  alias: [],
  description: 'locale_id_command_encoding'
}, {
  command: 'ignore',
  alias: [],
  description: 'locale_id_command_ignore'
}, {
  command: 'unignore',
  alias: [],
  description: 'locale_id_command_unignore'
}, {
  command: 'dice',
  alias: [],
  description: 'locale_id_command_dice'
}];
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["res"]) window._kiwi_exports["res"] = {};
window._kiwi_exports["res"]["autocompleteCommands"]
window._kiwi_exports.res.autocompleteCommands = exports.default ? exports.default : exports;
