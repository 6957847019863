"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _Object$keys2 = require("@babel/runtime-corejs3/core-js-stable/object/keys");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _filterInstanceProperty2 = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));

var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _lodash = _interopRequireDefault(require("lodash"));

var _strftime = _interopRequireDefault(require("strftime"));

var _ircFramework = _interopRequireDefault(require("irc-framework"));

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var _TypingMiddleware = _interopRequireDefault(require("./TypingMiddleware"));

var _ChathistoryMiddleware = _interopRequireDefault(require("./ChathistoryMiddleware"));

var ServerConnection = _interopRequireWildcard(require("./ServerConnection"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = _Object$keys2(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) { symbols = _filterInstanceProperty2(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { Object.defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

function create(state, network) {
  var networkid = network.id;
  var ircClient = new _ircFramework.default.Client({
    // Most options are set under the overloaded .connect()
    version: null,
    enable_chghost: true,
    enable_setname: true,
    message_max_length: 350
  });
  ircClient.requestCap('znc.in/self-message');
  ircClient.use((0, _ChathistoryMiddleware.default)());
  ircClient.use(clientMiddleware(state, network));
  ircClient.use((0, _TypingMiddleware.default)()); // Overload the connect() function to make sure we are connecting with the
  // most recent connection details from the network state

  var originalIrcClientConnect = ircClient.connect;

  ircClient.connect = function connect() {
    // Set some defaults if we don't have eveything
    if (!network.connection.nick) {
      network.connection.nick = 'Guest' + Math.floor(Math.random() * 100);
    }

    ircClient.options.host = network.connection.server;
    ircClient.options.port = network.connection.port;
    ircClient.options.tls = network.connection.tls;
    ircClient.options.path = network.connection.path;
    ircClient.options.password = network.connection.password;

    if (network.password) {
      ircClient.options.account = {
        account: network.connection.nick,
        password: network.password
      };
    } else {
      // No password so give an empty account config. This forces irc-framework to keep
      // the server password (options.password) separate from SASL
      ircClient.options.account = {};
    }

    ircClient.options.nick = network.connection.nick;
    ircClient.options.username = network.username || network.connection.nick;
    ircClient.options.gecos = network.gecos || 'https://kiwiirc.com/';
    ircClient.options.encoding = network.connection.encoding;
    ircClient.options.auto_reconnect = !!state.setting('autoReconnect'); // Apply any irc-fw options specified in kiwiirc config

    var configOptions = state.setting('ircFramework');

    if (configOptions) {
      (0, _assign.default)(ircClient.options, configOptions);
    }

    var eventObj = {
      network: network,
      transport: null
    };
    state.$emit('network.connecting', eventObj);

    if (eventObj.transport) {
      // A plugin might use its own transport of some kind
      ircClient.options.transport = eventObj.transport;
    } else if (!network.connection.direct) {
      // A direct connection uses a websocket to connect (note: some browsers limit
      // the number of connections to the same host!).
      // A non-direct connection will connect via the configured kiwi server using
      // with our own irc-framework compatible transport.
      ircClient.options.transport = ServerConnection.createChannelConstructor(state.settings.kiwiServer, (window.location.hash || '').substr(1), networkid);
    } else {
      // Use the irc-framework default transport
      ircClient.options.transport = undefined;
    }

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    originalIrcClientConnect.apply(ircClient, args);
  }; // Overload the raw() function so that we can emit outgoing IRC messages to plugins


  var originalIrcClientRaw = ircClient.raw;

  ircClient.raw = function raw() {
    var message = null;

    if ((arguments.length <= 0 ? undefined : arguments[0]) instanceof _ircFramework.default.Message) {
      message = arguments.length <= 0 ? undefined : arguments[0];
    } else {
      var rawString = ircClient.rawString.apply(ircClient, arguments);
      message = _ircFramework.default.ircLineParser(rawString);
    }

    var eventObj = {
      network: network,
      message: message,
      handled: false
    };
    state.$emit('ircout', eventObj);

    if (!eventObj.handled) {
      originalIrcClientRaw.apply(ircClient, [message]);
    }
  };

  ircClient.on('raw', function (event) {
    if (!network.setting('show_raw') && !state.setting('showRaw')) {
      return;
    }

    var buffer = state.getOrAddBufferByName(networkid, '*raw');
    state.addMessage(buffer, {
      time: Date.now(),
      nick: '',
      message: (event.from_server ? '[S] ' : '[C] ') + event.line
    });
  });
  ircClient.on('typing', function (event) {
    var user = state.getUser(network.id, event.nick);

    if (user) {
      user.typingStatus(event.target, event.status);
    }
  });
  return ircClient;
}

function clientMiddleware(state, network) {
  var networkid = network.id; // eslint-disable-next-line

  var numConnects = 0;
  var isRegistered = false;
  return function middlewareFn(client, rawEvents, parsedEvents) {
    parsedEvents.use(parsedEventsHandler);
    rawEvents.use(rawEventsHandler);
    client.on('connecting', function () {
      network.state_error = '';
      network.state = 'connecting';
      network.last_error = '';
      network.last_error_numeric = 0;
    });
    client.on('connected', function () {
      network.state_error = '';
      network.state = 'connected';
    });
    client.on('socket close', function (err) {
      isRegistered = false;
      network.state = 'disconnected';
      network.state_error = err || '';
      network.buffers.forEach(function (buffer) {
        if (!buffer) {
          return;
        }

        buffer.joined = false;
        buffer.clearUsers();
      });
    });
  };

  function rawEventsHandler(command, event, rawLine, client, next) {
    // Allow plugins to override raw IRC events
    var eventObj = _objectSpread(_objectSpread({}, event), {}, {
      raw: rawLine,
      handled: false
    });

    state.$emit('irc.raw', command, eventObj, network);

    if (eventObj.handled) {
      return;
    }

    state.$emit('irc.raw.' + command, command, eventObj, network);

    if (eventObj.handled) {
      return;
    }

    if (command === '002') {
      // Your host is server.example.net, running version InspIRCd-2.0
      var param = event.params[1] || '';
      var m = param.match(/running version (.*)$/);
      network.ircd = m ? m[1] : '';
    } // SASL failed auth


    if (command === '904') {
      if (!network.state !== 'connected') {
        network.last_error = 'Invalid login';

        if (state.setting('disconnectOnSaslFail')) {
          network.ircClient.connection.end();
        }
      }

      var serverBuffer = network.serverBuffer();
      state.addMessage(serverBuffer, {
        time: Date.now(),
        nick: '*',
        message: 'Invalid login'
      });
    }

    if (command === 'CAP' && network.setting('show_raw_caps')) {
      var params = (0, _toConsumableArray2.default)(event.params);

      if (params[params.length - 1].indexOf(' ') > -1) {
        params[params.length - 1] = ':' + params[params.length - 1];
      }

      var buffer = network.serverBuffer();
      state.addMessage(buffer, {
        time: Date.now(),
        nick: '',
        message: event.command + ' ' + params.join(' ')
      });
    }

    next();
  }

  function parsedEventsHandler(command, event, client, next) {
    // Trigger this event through the state object first. If it's been handled
    // somewhere else then we ignore it.
    var ircEventObj = {
      handled: false
    };
    state.$emit('irc.' + command, event, network, ircEventObj);

    if (ircEventObj.handled) {
      next();
      return;
    } // Ignore any of the control messages. They're transport related to kiwi internals


    if (event && event.command === 'CONTROL') {
      next();
      return;
    } // If there is a time difference between this client and the server, convert it
    // to match our local time so it makes sense to the user


    var eventTime = event && event.time ? network.ircClient.network.timeToLocal(event.time) : Date.now();
    var serverTime = event && event.time || 0;

    if (command === 'channel_redirect') {
      var b = network.bufferByName(event.from);

      if (b) {
        (0, _flags.default)(b).redirect_to = event.to;
      }
    }

    if (command === 'registered') {
      isRegistered = true;
      network.nick = event.nick;
      state.addUser(networkid, {
        nick: event.nick,
        username: client.user.username
      });
      var serverBuffer = network.serverBuffer();
      state.addMessage(serverBuffer, {
        time: eventTime,
        server_time: serverTime,
        nick: '',
        message: TextFormatting.t('connected_to', {
          network: client.network.name
        })
      }); // Get some extra info about ourselves

      client.raw('WHO ' + event.nick);

      if (network.auto_commands) {
        network.auto_commands.split('\n').forEach(function (line) {
          state.$emit('input.raw', line[0] === '/' ? line : "/".concat(line));
        });
      } // Join our channels
      // If under bouncer mode, the bouncer will send the channels were joined to instead.


      if (!network.connection.bncnetid) {
        network.buffers.forEach(function (buffer) {
          if (buffer.isChannel() && buffer.enabled) {
            client.join(buffer.name, buffer.key);
          }
        });
      }

      numConnects++;
    }

    if (command === 'server options') {
      // If the network name has changed from the irc-framework default, update ours
      // Also if it isn't a BNC network as the name is then derived from the BNC info instead
      if (client.network.name !== 'Network' && !network.connection.bncnetid) {
        network.name = client.network.name;
      }
    } // Show unhandled data from the server in the servers tab


    if (command === 'unknown command') {
      if (event.command === '486' || event.command === '477') {
        // You must log in with services to message this user
        var targetNick = event.params[1];
        var buffer = state.getOrAddBufferByName(network.id, targetNick); // Only add this message if it does not match the previous message
        // Typing status messages can cause a spam of this error type

        state.addMessageNoRepeat(buffer, {
          time: eventTime,
          server_time: serverTime,
          nick: '*',
          message: event.params[2],
          type: 'error'
        });
      } else {
        var _buffer = network.serverBuffer();

        var message = ''; // Only show non-numeric commands

        if (!event.command.match(/^\d+$/)) {
          message += event.command + ' ';
        }

        var containsNick = event.params[0] === network.ircClient.user.nick;
        var isChannelMessage = network.isChannelName(event.params[1]); // Strip out the nick if it's the first params (many commands include this)

        if (containsNick && isChannelMessage) {
          var _context;

          var channelBuffer = network.bufferByName(event.params[1]);

          if (channelBuffer) {
            _buffer = channelBuffer;
          }

          message += (0, _slice.default)(_context = event.params).call(_context, 2).join(', ');
        } else if (containsNick) {
          var _context2;

          message += (0, _slice.default)(_context2 = event.params).call(_context2, 1).join(', ');
        } else {
          message += event.params.join(', ');
        }

        state.addMessage(_buffer, {
          nick: '',
          message: message
        });
      }
    }

    if (command.toLowerCase() === 'batch start chathistory' && client.chathistory) {
      // We have a new batch of messages. To prevent duplicate messages being shown, we remove
      // all messages we have locally in the range of these new messages so that the new block
      // of messages we recieved are displayed accurately. Each message in the block will
      // trigger a 'message' event after this.
      var startTime = 0;
      var endTime = 0;
      event.commands.forEach(function (message) {
        if (message.time && message.time > endTime) {
          endTime = message.time;
        }

        if (message.time && message.time < startTime) {
          startTime = message.time;
        }
      });

      if (!startTime || !endTime) {
        return;
      }

      var _buffer2 = state.getBufferByName(networkid, event.params[0]);

      if (_buffer2) {
        _buffer2.clearMessageRange(startTime, endTime);
      }
    }

    if (command === 'message') {
      var isPrivateMessage = false;
      var bufferName = event.from_server ? '*' : event.target; // If the message came from a batch then params[0] is the bufferName

      if (event.batch && event.batch.type === 'chathistory' && event.batch.params[0]) {
        bufferName = event.batch.params[0];
        isPrivateMessage = !network.isChannelName(bufferName);
      } else if (!event.from_server && event.target === client.user.nick) {
        // PMs should go to a buffer with the name of the other user
        isPrivateMessage = true;
        bufferName = event.nick;
      } // Chanserv sometimes PMs messages about a channel on join in the format of
      // [#channel] welcome!
      // Redirect these to #channel


      if (event.nick.toLowerCase() === 'chanserv' && isPrivateMessage && event.message[0] === '[') {
        bufferName = event.message.substr(1, event.message.indexOf(']') - 1);
      } // Notices from somewhere when we don't have an existing buffer for them should go into
      // the server tab. ie. notices from servers


      if (event.type === 'notice') {
        var existingBuffer = state.getBufferByName(networkid, bufferName);
        var noticeActiveBuffer = state.setting('noticeActiveBuffer');
        var activeBuffer = state.getActiveBuffer();
        var hasActiveBuffer = activeBuffer && activeBuffer.networkid === networkid; // If we don't have a buffer for this notice sender, either show it in our active
        // buffer or the server buffer

        if (!existingBuffer) {
          if (noticeActiveBuffer && hasActiveBuffer) {
            bufferName = activeBuffer.name;
          } else {
            bufferName = '*';
          }
        }
      }

      var PM_BLOCK_BLOCKED = false; // const PM_BLOCK_NOT_BLOCKED = true;

      var PM_BLOCK_REQUIRES_CHECK = null;
      var pmBlock = network.isNickExemptFromPmBlocks(event.nick);
      var blockNewPms = state.setting('buffers.block_pms');

      var _buffer3 = state.getBufferByName(networkid, bufferName);

      var textFormatType = 'privmsg';

      if (event.type === 'action') {
        textFormatType = 'action';
      } else if (event.type === 'notice') {
        textFormatType = 'notice';
      }

      var messageBody = TextFormatting.formatText(textFormatType, {
        nick: event.nick,
        username: event.ident,
        host: event.hostname,
        text: event.message
      });
      var _message = {
        time: eventTime,
        server_time: serverTime,
        nick: event.nick,
        message: messageBody,
        type: event.type,
        tags: event.tags || {}
      }; // If this is a new PM and the sending user is not exempt from blocks, ignore it

      if (blockNewPms && isPrivateMessage && !_buffer3 && pmBlock === PM_BLOCK_BLOCKED) {
        return;
      } // If we need to manually check if this user is blocked..
      // PM_BLOCK_REQUIRES_CHECK means we should whois the user to get their oper status. We
      // allways allow messages from opers.


      if (blockNewPms && isPrivateMessage && !_buffer3 && pmBlock === PM_BLOCK_REQUIRES_CHECK) {
        // if the nick is in pendingPms it has already issued a whois request
        var awaitingWhois = !!(0, _find.default)(_lodash.default).call(_lodash.default, network.pendingPms, {
          nick: event.nick
        });
        network.pendingPms.push({
          bufferName: bufferName,
          message: _message
        }); // Don't send another whois if we are already awaiting another

        if (awaitingWhois) {
          return;
        }

        network.ircClient.whois(event.nick, event.nick, function (whoisData) {
          network.pendingPms.forEach(function (pm, idx, obj) {
            var nickLower = pm.message.nick.toLowerCase();

            if (nickLower === whoisData.nick.toLowerCase()) {
              if (whoisData.operator) {
                _buffer3 = state.getOrAddBufferByName(network.id, pm.bufferName);
                state.addMessage(_buffer3, pm.message);
              }

              (0, _splice.default)(obj).call(obj, idx, 1);
            }
          });
        });
        return;
      } // Make sure we have a buffer for our message


      if (!_buffer3) {
        _buffer3 = state.getOrAddBufferByName(networkid, bufferName);
      }

      state.addMessage(_buffer3, _message);
    }

    if (command === 'wallops') {
      var _buffer4 = state.getOrAddBufferByName(networkid, '*');

      var _messageBody = TextFormatting.formatText('wallops', {
        text: event.message
      });

      state.addMessage(_buffer4, {
        time: eventTime,
        server_time: serverTime,
        nick: event.nick,
        message: _messageBody,
        type: 'wallops'
      });
    }

    if (command === 'join') {
      // If we have any buffers marked as being redirected to this new channel, update
      // that buffer instead of creating a new one
      if (event.nick === client.user.nick) {
        network.buffers.forEach(function (b) {
          if (((0, _flags.default)(b).redirect_to || '').toLowerCase() === event.channel.toLowerCase()) {
            state.$delete((0, _flags.default)(b), 'redirect_to');
            b.rename(event.channel);
          }
        });
      }

      var _buffer5 = state.getOrAddBufferByName(networkid, event.channel); // The case does not match, update buffer.name to the casing sent by the server


      if (_buffer5.name !== event.channel) {
        _buffer5.rename(event.channel);
      }

      state.addUserToBuffer(_buffer5, {
        nick: event.nick,
        username: event.ident,
        host: event.hostname,
        realname: event.gecos,
        account: event.account || ''
      });

      if (event.nick === client.user.nick) {
        _buffer5.enabled = true;
        _buffer5.joined = true;
        (0, _flags.default)(_buffer5).channel_badkey = false;
        network.ircClient.raw('MODE', event.channel);
        network.ircClient.who(event.channel);
      }

      var nick = _buffer5.setting('show_hostnames') ? TextFormatting.formatUserFull(event) : TextFormatting.formatUser(event);

      var _messageBody2 = TextFormatting.formatAndT('channel_join', null, 'has_joined', {
        nick: nick
      });

      state.addMessage(_buffer5, {
        time: eventTime,
        server_time: serverTime,
        nick: event.nick,
        message: _messageBody2,
        type: 'traffic',
        type_extra: 'join'
      });
    }

    if (command === 'kick') {
      var _buffer6 = state.getOrAddBufferByName(networkid, event.channel);

      state.removeUserFromBuffer(_buffer6, event.kicked);
      var _messageBody3 = '';

      if (event.kicked === client.user.nick) {
        _buffer6.joined = false;

        _buffer6.clearUsers();

        _messageBody3 = TextFormatting.formatAndT('channel_selfkick', {
          reason: event.message
        }, 'kicked_you_from', {
          nick: TextFormatting.formatUser(event),
          channel: event.channel
        });
      } else {
        _messageBody3 = TextFormatting.formatAndT('channel_kicked', {
          reason: event.message
        }, 'was_kicked_from', {
          nick: event.kicked,
          channel: event.channel,
          chanop: TextFormatting.formatUser(event.nick)
        });
      }

      state.addMessage(_buffer6, {
        time: eventTime,
        server_time: serverTime,
        nick: event.nick,
        message: _messageBody3,
        type: 'traffic',
        type_extra: 'kick'
      });
    }

    if (command === 'part') {
      var _buffer7 = state.getBufferByName(networkid, event.channel);

      if (!_buffer7) {
        return;
      }

      state.removeUserFromBuffer(_buffer7, event.nick);

      if (event.nick === client.user.nick) {
        _buffer7.joined = false;
        _buffer7.enabled = false;

        _buffer7.clearUsers();
      } // Remove the user from network state if no remaining common channels


      var remainingBuffers = state.getBuffersWithUser(networkid, event.nick);

      if (remainingBuffers.length === 0) {
        state.removeUser(networkid, {
          nick: event.nick
        });
      }

      var _nick = _buffer7.setting('show_hostnames') ? TextFormatting.formatUserFull(event) : TextFormatting.formatUser(event);

      var _messageBody4 = TextFormatting.formatAndT('channel_part', {
        reason: event.message
      }, 'has_left', {
        nick: _nick
      });

      state.addMessage(_buffer7, {
        time: eventTime,
        server_time: serverTime,
        nick: event.nick,
        message: _messageBody4,
        type: 'traffic',
        type_extra: 'part'
      });
    }

    if (command === 'quit') {
      var buffers = state.getBuffersWithUser(networkid, event.nick);
      buffers.forEach(function (buffer) {
        if (!buffer) {
          return;
        }

        if (event.nick === client.user.nick) {
          buffer.joined = false;
          buffer.clearUsers();
        }

        var nick = buffer.setting('show_hostnames') ? TextFormatting.formatUserFull(event) : TextFormatting.formatUser(event);
        var messageBody = TextFormatting.formatAndT('channel_quit', {
          reason: event.message
        }, 'has_left', {
          nick: nick
        });
        state.addMessage(buffer, {
          time: eventTime,
          server_time: serverTime,
          nick: event.nick,
          message: messageBody,
          type: 'traffic',
          type_extra: 'quit'
        });
      }); // Set the user as away before removing so away status indicators are updated

      var user = state.getUser(networkid, event.nick);

      if (user) {
        user.away = 'offline';
      }

      state.removeUser(networkid, {
        nick: event.nick
      });
    }

    if (command === 'invite') {
      var _buffer8 = network.serverBuffer();

      var activeNetwork = state.getActiveNetwork();

      var _activeBuffer = state.getActiveBuffer();

      if (network === activeNetwork && !_activeBuffer.isSpecial()) {
        _buffer8 = _activeBuffer;
      }

      state.addMessage(_buffer8, {
        nick: '',
        time: eventTime,
        server_time: serverTime,
        type: 'invite',
        message: TextFormatting.t('invited_you', {
          nick: event.nick,
          channel: event.channel
        })
      });
    }

    if (command === 'account') {
      state.addUser(networkid, {
        nick: event.nick,
        account: event.account || ''
      });
    }

    if (command === 'whois') {
      var obj = {
        nick: event.nick,
        host: event.hostname,
        username: event.ident,
        away: event.away || '',
        realname: event.real_name,
        hasWhois: true
      }; // Some other optional bits of info

      ['actual_host', 'helpop', 'bot', 'server', 'server_info', 'operator', 'channels', 'modes', 'idle', 'logon', 'registered_nick', 'account', 'secure', 'certfp', 'special'].forEach(function (prop) {
        if (typeof event[prop] !== 'undefined') {
          obj[prop] = event[prop];
        }
      });
      state.addUser(networkid, obj);
    }

    if (command === 'away') {
      state.addUser(networkid, {
        nick: event.nick,
        away: event.message || ''
      });

      var _buffer9 = state.getActiveBuffer();

      if (_buffer9 && event.nick === network.nick) {
        network.away = 'away';
        state.addMessage(_buffer9, {
          time: eventTime,
          server_time: serverTime,
          nick: '*',
          type: 'presence',
          message: event.message
        });
      }
    }

    if (command === 'back') {
      state.addUser(networkid, {
        nick: event.nick,
        away: ''
      });

      var _buffer10 = state.getActiveBuffer();

      if (_buffer10 && event.nick === network.nick) {
        network.away = '';
        state.addMessage(_buffer10, {
          time: eventTime,
          server_time: serverTime,
          nick: '*',
          type: 'presence',
          message: event.message
        });
      }
    }

    if (command === 'wholist') {
      state.usersTransaction(networkid, function (users) {
        event.users.forEach(function (eventUser) {
          var userObj = {
            nick: eventUser.nick,
            host: eventUser.hostname || undefined,
            username: eventUser.ident || undefined,
            away: eventUser.away ? 'Away' : '',
            realname: eventUser.real_name,
            account: eventUser.account || undefined
          };
          var user = state.addUser(networkid, userObj, users);

          if (!user) {
            // Should never happen as this network should always exist
            return;
          }

          var buffer = network.bufferByName(eventUser.channel);

          if (!buffer || !user.buffers[buffer.id]) {
            return;
          } // Add all the user channel modes


          var modes = user.buffers[buffer.id].modes;
          eventUser.channel_modes.forEach(function (mode) {
            if (modes.indexOf(mode) === -1) {
              modes.push(mode);
            }
          });
        });
      });
    }

    if (command === 'channel list start') {
      network.channel_list_cache = [];
      network.channel_list_state = 'updating';
    }

    if (command === 'channel list') {
      var _context3;

      network.channel_list_state = 'updating'; // Filter private channels from the channel list

      var filteredEvent = (0, _filter.default)(_lodash.default).call(_lodash.default, event, function (o) {
        return o.channel !== '*';
      }); // Store the channels in channel_list_cache before moving it all to
      // channel_list at the end. This gives a huge performance boost since
      // it doesn't need to be all reactive for every update

      network.channel_list_cache = (0, _concat.default)(_context3 = network.channel_list_cache || []).call(_context3, filteredEvent);
    }

    if (command === 'channel list end') {
      network.channel_list = network.channel_list_cache || [];
      network.channel_list_state = 'updated';
      delete network.channel_list_cache;
    }

    if (command === 'motd') {
      var _buffer11 = network.serverBuffer();

      var _messageBody5 = TextFormatting.formatText('motd', {
        text: event.motd
      });

      state.addMessage(_buffer11, {
        time: eventTime,
        server_time: serverTime,
        nick: '',
        message: _messageBody5,
        type: 'motd'
      });
    }

    if (command === 'nick in use' && !client.connection.registered) {
      var newNick = client.user.nick + rand(1, 100);

      var _messageBody6 = TextFormatting.formatAndT('nickname_alreadyinuse', null, 'nick_in_use_retrying', {
        nick: client.user.nick,
        newnick: newNick
      });

      network.buffers.forEach(function (b) {
        state.addMessage(b, {
          time: eventTime,
          server_time: serverTime,
          nick: '',
          message: _messageBody6,
          type: 'error'
        });
      });
      client.changeNick(newNick);
    }

    if (command === 'nick in use' && client.connection.registered) {
      var _buffer12 = state.getActiveBuffer();

      _buffer12 && state.addMessage(_buffer12, {
        time: eventTime,
        server_time: serverTime,
        nick: '',
        type: 'error',
        message: "The nickname '".concat(event.nick, "' is already in use!")
      });
    }

    if (command === 'nick') {
      if (event.nick === client.user.nick) {
        network.nick = event.new_nick;
      }

      state.changeUserNick(networkid, event.nick, event.new_nick);

      var _messageBody7 = TextFormatting.formatAndT('nick_changed', null, 'now_known_as', {
        nick: event.nick,
        newnick: event.new_nick
      });

      var _buffers = state.getBuffersWithUser(networkid, event.new_nick);

      _buffers.forEach(function (buffer) {
        state.addMessage(buffer, {
          time: eventTime,
          server_time: serverTime,
          nick: '',
          message: _messageBody7,
          type: 'nick'
        });
      });
    }

    if (command === 'userlist') {
      var _context4;

      var _buffer13 = state.getOrAddBufferByName(networkid, event.channel);

      var hadExistingUsers = (0, _filter.default)(_context4 = (0, _keys.default)(_buffer13.users)).call(_context4, function (u) {
        return u !== network.ircClient.user.nick;
      }).length > 0;
      var users = [];
      event.users.forEach(function (user) {
        users.push({
          user: {
            nick: user.nick,
            username: user.ident,
            hostname: user.hostname
          },
          modes: user.modes
        });
      });
      state.addMultipleUsersToBuffer(_buffer13, users);

      if (!hadExistingUsers && network.ircClient.chathistory.isSupported()) {
        var correctBuffer = _buffer13.isChannel() || _buffer13.isQuery(); // TODO: If this is a reconnect (numConnects > 1) then paginate backwards
        //       until we reach our last message.
        //       OR
        //       Add a marker at the gap between this new chathistory block starts and when
        //       the existing messages end so that we can add a "load missing messages"
        //       button there or have it auto request them when it scrolls into view


        if (correctBuffer) {
          _buffer13.requestLatestScrollback();
        }
      }
    }

    if (command === 'user updated') {
      var _user = network.userByName(event.nick);

      if (_user) {
        (0, _entries.default)(event).forEach(function (_ref) {
          var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
              key = _ref2[0],
              val = _ref2[1];

          if (key.indexOf('new_') !== 0) {
            return;
          }

          var paramName = key.substr(4);

          switch (paramName) {
            case 'gecos':
              _user.realname = val;
              break;

            case 'ident':
              _user.username = val;
              break;

            case 'hostname':
              _user.host = val;
              break;

            default:
          }
        });
      }
    }

    if (command === 'channel info') {
      var _buffer14 = network.bufferByName(event.channel);

      if (!_buffer14) {
        return;
      }

      if (event.modes) {
        var modeStrs = [];
        event.modes.forEach(function (mode) {
          var adding = mode.mode[0] === '+';
          var modeChar = mode.mode.substr(1);

          if (adding) {
            state.$set(_buffer14.modes, modeChar, mode.param);
          } else if (!adding) {
            state.$delete(_buffer14.modes, modeChar);
          }

          modeStrs.push(mode.mode + (mode.param ? ' ' + mode.param : ''));
        });

        if ((0, _flags.default)(_buffer14).requested_modes) {
          state.addMessage(_buffer14, {
            time: eventTime,
            server_time: serverTime,
            nick: '*',
            message: _buffer14.name + ' ' + modeStrs.join(', ')
          });
        }
      }

      if (event.created_at) {
        _buffer14.created_at = new Date(event.created_at * 1000);
      }

      if (event.created_at && (0, _flags.default)(_buffer14).requested_modes) {
        var tFormat = _buffer14.setting('timestamp_full_format');

        var timeCreated = tFormat ? (0, _strftime.default)(tFormat, new Date(event.created_at * 1000)) : new Date(event.created_at * 1000).toLocaleString();
        state.addMessage(_buffer14, {
          time: eventTime,
          server_time: serverTime,
          nick: '*',
          message: _buffer14.name + ' ' + timeCreated
        });
      }
    }

    if (command === 'mode') {
      var _buffer15 = network.bufferByName(event.target);

      var _modeStrs = {};

      if (_buffer15) {
        // Join all the same mode changes together so they can be shown on one
        // line such as "prawnsalad sets +b on nick1, nick2"
        event.modes.forEach(function (mode) {
          _modeStrs[mode.mode] = _modeStrs[mode.mode] || []; // If this mode has a user prefix then we need to update the user object

          var prefix = (0, _find.default)(_lodash.default).call(_lodash.default, network.ircClient.network.options.PREFIX, {
            mode: mode.mode[1]
          });

          if (prefix) {
            var _user2 = state.getUser(network.id, mode.param);

            if (_user2) {
              var adding = mode.mode[0] === '+';
              var modes = _user2.buffers[_buffer15.id].modes;
              var modeIdx = modes.indexOf(prefix.mode); // Add or remove the mode from the users mode list

              if (adding && modeIdx === -1) {
                modes.push(prefix.mode);
              } else if (!adding && modeIdx > -1) {
                (0, _splice.default)(modes).call(modes, modeIdx, 1);
              }
            }

            _modeStrs[mode.mode].push({
              target: mode.param
            });
          } else {
            // Not a user prefix, add it as a channel mode
            // TODO: Why are these not appearing as the 'channel info' command?
            var _adding = mode.mode[0] === '+';

            var modeChar = mode.mode.substr(1);

            if (_adding) {
              state.$set(_buffer15.modes, modeChar, mode.param);
            } else if (!_adding) {
              state.$delete(_buffer15.modes, modeChar);
            }

            _modeStrs[mode.mode].push({
              target: _buffer15.name,
              param: mode.param
            });
          }
        }); // Mode -> locale ID mappings
        // If a mode isn't found here, the local ID modes_other is used

        var modeLocaleIds = {
          '+o': 'modes_give_ops',
          '-o': 'modes_take_ops',
          '+h': 'modes_give_halfops',
          '-h': 'modes_take_halfops',
          '+v': 'modes_give_voice',
          '-v': 'modes_take_voice',
          '+a': 'modes_give_admin',
          '-a': 'modes_take_admin',
          '+q': 'modes_give_owner',
          '-q': 'modes_take_owner',
          '+b': 'modes_gives_ban',
          '-b': 'modes_takes_ban'
        };
        var prefixes = network.ircClient.network.options.PREFIX;
        (0, _keys.default)(modeLocaleIds).forEach(function (mode) {
          var supported = mode[1] === 'b' || (0, _find.default)(prefixes).call(prefixes, function (p) {
            return mode[1] === p.mode;
          });

          if (!supported) {
            delete modeLocaleIds[mode];
          }
        }); // Some modes have specific data for its locale data while most
        // use a default. The returned objects are passed to the translation
        // functions to build the translation

        var modeLocaleDataBuilders = {
          default: function _default(targets, mode) {
            return {
              mode: mode + (targets[0].param ? ' ' + targets[0].param : ''),
              target: (0, _map.default)(targets).call(targets, function (t) {
                return t.target;
              }).join(', '),
              nick: event.nick
            };
          },
          b: function b(targets, mode) {
            return {
              mode: mode,
              target: targets[0].param ? targets[0].param : '',
              nick: event.nick
            };
          }
        }; // Show one line per mode, listing each effecting user

        _lodash.default.each(_modeStrs, function (targets, mode) {
          // Find a locale data builder for this mode
          var builders = modeLocaleDataBuilders;
          var localeDataFn = builders[mode[1]] || builders.default;
          var localeData = localeDataFn(targets, mode); // Translate using the built locale data

          var localeKey = modeLocaleIds[mode] || 'modes_other';
          var text = TextFormatting.t(localeKey, localeData);
          var messageBody = TextFormatting.formatText('mode', {
            nick: event.nick,
            username: event.ident,
            host: event.hostname,
            target: (0, _map.default)(targets).call(targets, function (t) {
              return t.target;
            }).join(', '),
            text: text
          });
          state.addMessage(_buffer15, {
            time: eventTime,
            server_time: serverTime,
            nick: '',
            message: messageBody,
            type: 'mode'
          });
        });
      } else {
        // target is not a channel buffer (user mode ?)
        // if mode had param, show in a new line
        var modeslines = {}; // Group each - or + modes to each of their own message lines

        event.modes.forEach(function (mode) {
          if (mode.param) {
            modeslines[mode.mode] = ' ' + mode.param;
          } else if (mode.mode[0] === '-') {
            var _context5;

            if (!modeslines['-']) {
              modeslines['-'] = '';
            }

            modeslines['-'] += (0, _slice.default)(_context5 = mode.mode).call(_context5, 1);
          } else {
            if (!modeslines['+']) {
              modeslines['+'] = '';
            }

            if (mode.mode[0] === '+') {
              var _context6;

              modeslines['+'] += (0, _slice.default)(_context6 = mode.mode).call(_context6, 1);
            } else {
              modeslines['+'] += mode.mode;
            }
          }
        });

        var _serverBuffer = network.serverBuffer();

        _lodash.default.each(modeslines, function (mode, value) {
          var text = TextFormatting.t('modes_other', {
            nick: event.nick,
            target: event.target,
            mode: value + mode
          });
          var messageBody = TextFormatting.formatText('mode', {
            nick: event.nick,
            username: event.ident,
            host: event.hostname,
            target: event.target,
            text: text
          });
          state.addMessage(_serverBuffer, {
            time: eventTime,
            server_time: serverTime,
            nick: '',
            message: messageBody,
            type: 'mode'
          });
        });
      }
    }

    if (command === 'banlist') {
      var _buffer16 = state.getBufferByName(networkid, event.channel);

      if (_buffer16 && (0, _flags.default)(_buffer16).requested_banlist) {
        if (!event.bans || event.bans.length === 0) {
          state.addMessage(_buffer16, {
            time: eventTime,
            server_time: serverTime,
            nick: '',
            message: TextFormatting.t('bans_nobody'),
            type: 'banlist'
          });
        } else {
          var banText = '';

          _lodash.default.each(event.bans, function (ban) {
            var _context7, _context8;

            var dateStr = new Date(ban.banned_at * 1000).toDateString();
            banText += (0, _concat.default)(_context7 = (0, _concat.default)(_context8 = "+b ".concat(ban.banned, " [by ")).call(_context8, ban.banned_by, ", ")).call(_context7, dateStr, "]\n");
          });

          state.addMessage(_buffer16, {
            time: eventTime,
            server_time: serverTime,
            nick: '*',
            message: banText,
            type: 'banlist'
          });
        }

        (0, _flags.default)(_buffer16).requested_banlist = false;
      }
    }

    if (command === 'topic') {
      var _context9;

      var _buffer17 = state.getOrAddBufferByName(networkid, event.channel);

      _buffer17.topic = event.topic || '';
      var _messageBody8 = '';

      if (event.nick) {
        _messageBody8 = TextFormatting.formatAndT('channel_topic', null, 'changed_topic_to', {
          nick: event.nick,
          topic: event.topic
        });
      } else if ((0, _trim.default)(_context9 = _buffer17.topic).call(_context9)) {
        _messageBody8 = TextFormatting.formatText('channel_topic', _buffer17.topic);
      }

      if (_messageBody8) {
        state.addMessage(_buffer17, {
          time: eventTime,
          server_time: serverTime,
          nick: '',
          message: _messageBody8,
          type: 'topic'
        });
      }
    }

    if (command === 'help') {
      var _buffer18 = state.getOrAddBufferByName(networkid, '*help');

      state.addMessage(_buffer18, {
        time: eventTime,
        server_time: serverTime,
        nick: '',
        message: event.help,
        type: 'help',
        tags: event.tags || {}
      });
    }

    if (command === 'ctcp response' || command === 'ctcp request') {
      var _buffer19 = network.bufferByName(event.target) || network.serverBuffer();

      var textFormatId = command === 'ctcp response' ? 'ctcp_response' : 'ctcp_request';

      var _messageBody9 = TextFormatting.formatText(textFormatId, {
        nick: event.nick,
        message: event.message,
        type: event.type
      });

      state.addMessage(_buffer19, {
        time: eventTime,
        server_time: serverTime,
        nick: '',
        message: _messageBody9,
        type: 'error'
      });

      if (command === 'ctcp request' && event.type === 'VERSION') {
        var target = event.from_server ? event.hostname : event.nick;
        client.ctcpResponse(target, 'VERSION', 'Kiwi IRC');
      }
    }

    if (command === 'nick invalid') {
      var _messageBody10 = TextFormatting.formatText('general_error', {
        text: event.reason
      });

      var _buffer20 = state.getActiveBuffer();

      state.addMessage(_buffer20, {
        time: eventTime,
        server_time: serverTime,
        nick: '',
        message: _messageBody10,
        type: 'error'
      });

      if (!isRegistered) {
        network.last_error_numeric = 432;
        network.last_error = event.reason;
        network.ircClient.quit();
      }
    }

    if (command === 'irc error') {
      var _buffer21;

      if (event.channel || event.nick) {
        _buffer21 = state.getOrAddBufferByName(network.id, event.channel || event.nick);
      }

      if (!_buffer21) {
        _buffer21 = network.serverBuffer();
      }

      if (!_buffer21) {
        // we could not find a buffer, this is likely because the network was removed
        return;
      } // TODO: Some of these errors contain a .error property which we can match against,
      // ie. password_mismatch.


      if (event.error === 'bad_channel_key') {
        (0, _flags.default)(_buffer21).channel_badkey = true;
      } // ignore error 432 (erroneous nickname) as it is handled above


      if (event.reason && network.last_error_numeric !== 432) {
        if (!isRegistered) {
          network.last_error = event.reason;
        }

        var _messageBody11 = TextFormatting.formatText('general_error', {
          text: event.reason || event.error
        });

        var _message2 = {
          time: eventTime,
          server_time: serverTime,
          nick: '',
          message: _messageBody11,
          type: 'error'
        };

        if (event.error === 'cannot_send_to_channel') {
          // Only add this message if it does not match the previous message
          // Typing status messages can cause a spam of this error type
          state.addMessageNoRepeat(_buffer21, _message2);
          return;
        }

        state.addMessage(_buffer21, _message2);
      } // Getting an error about a channel while we are not joined means that we couldn't join
      // or do some action on it. Disable it until we manually reattempt to join.


      if (_buffer21.isChannel() && !_buffer21.joined) {
        _buffer21.enabled = false;
      }
    }

    next();
  }
}

function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["IrcClient"]
window._kiwi_exports.libs.IrcClient = exports.default ? exports.default : exports;
