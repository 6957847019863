"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _default = {
  components: {},
  props: ['buffer'],
  data: function data() {
    return {};
  },
  computed: {
    settingAlertOn: {
      get: function getSettingAlertOn() {
        return this.buffer.setting('alert_on');
      },
      set: function setSettingAlertOn(val) {
        var network = this.buffer.getNetwork();
        var netId = network.connection.bncnetid;

        if (netId) {
          var _context, _context2;

          // If this buffer is on a BOUNCER account, update the setting there too
          // TODO: Move this to BouncerProvider snapshots
          network.ircClient.raw((0, _concat.default)(_context = (0, _concat.default)(_context2 = "BOUNCER changebuffer ".concat(netId, " ")).call(_context2, this.buffer.name, " notify=")).call(_context, val));
        }

        return this.buffer.setting('alert_on', val);
      }
    },
    settingMuteSound: {
      get: function getSettingAlertOn() {
        return this.buffer.setting('mute_sound');
      },
      set: function setSettingAlertOn(val) {
        return this.buffer.setting('mute_sound', val);
      }
    },
    settingHideMessageCount: {
      get: function getsettingHideMessageCount() {
        return this.buffer.setting('hide_message_counts');
      },
      set: function setsettingHideMessageCount(val) {
        return this.buffer.setting('hide_message_counts', val);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["BufferSettings"]
window._kiwi_exports.components.BufferSettings = exports.default ? exports.default : exports;
