"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default2 = {
  props: {
    usePreset: {
      type: Boolean,
      default: true
    },
    networkList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    connection: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      name: '',
      presetNetworks: [],
      showCustom: true,
      willEmit: false
    };
  },
  computed: {
    presetServer: {
      set: function set(newVal) {
        if (newVal === 'custom') {
          this.name = '';
          this.connection.server = '';
          this.connection.port = 6697;
          this.connection.tls = true;
          this.showCustom = true;
        } else {
          var addr = Misc.parsePresetServer(newVal);
          this.name = addr.name;
          this.connection.server = addr.server;
          this.connection.port = addr.port;
          this.connection.tls = addr.tls;
          this.showCustom = false;
        }
      },
      get: function get() {
        return this.showCustom ? 'custom' : this.toUri(this.connection);
      }
    }
  },
  created: function created() {
    if (this.networkList) {
      this.importUris(this.networkList);
    } // If the given network is in the preset server list, select it


    var con = this.connection;

    if ((0, _find.default)(_lodash.default).call(_lodash.default, this.presetNetworks, function (s) {
      var match = s.server === con.server && s.port === con.port && s.tls === con.tls;
      return match;
    })) {
      this.showCustom = false;
    }
  },
  methods: {
    toUri: function toUri(s) {
      var _context, _context2;

      return (0, _concat.default)(_context = (0, _concat.default)(_context2 = "".concat(s.server, ":")).call(_context2, s.tls ? '+' : '')).call(_context, s.port);
    },
    toggleTls: function toggleTls() {
      this.connection.tls = !this.connection.tls; // Switching the port only if were currently using the most common TLS/plain text ports

      if (this.connection.tls && this.connection.port === 6667) {
        this.connection.port = 6697;
      } else if (!this.connection.tls && this.connection.port === 6697) {
        this.connection.port = 6667;
      }
    },
    importUris: function importUris(serverList) {
      // [ 'freenode|irc.freenode.net:+6697', 'irc.snoonet.org:6667' ]
      var servers = (0, _map.default)(serverList).call(serverList, function (s) {
        return Misc.parsePresetServer(s);
      });
      this.$set(this, 'presetNetworks', servers);
    }
  }
};
exports.default = _default2;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ServerSelector"]
window._kiwi_exports.components.ServerSelector = exports.default ? exports.default : exports;
