"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  props: ['ircinput'],
  computed: {
    emojis: function emojis() {
      var list = {};
      var available = this.$state.setting('emojis');

      _lodash.default.each(available, function (code, ascii) {
        if (!code) {
          // Emoji has an empty value, skip
          return;
        }

        list[code] = ascii;
      });

      return list;
    },
    location: function location() {
      return this.$state.setting('emojiLocation');
    }
  },
  methods: {
    onImgClick: function onImgClick(event) {
      var url = window.getComputedStyle(event.target, null).getPropertyValue('background-image');
      url = Misc.extractURL(url);
      var code = event.target.dataset.code;
      this.ircinput.addImg(code, url);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["inputtools"]) window._kiwi_exports["components"]["inputtools"] = {};
window._kiwi_exports["components"]["inputtools"]["Emoji"]
window._kiwi_exports.components.inputtools.Emoji = exports.default ? exports.default : exports;
