"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _state = _interopRequireDefault(require("@/libs/state"));

var methods = {
  props: {},
  toggleSelfAway: function toggleSelfAway(props) {
    // let props = this.props;
    if (props.isUserSelf) {
      var val = props.user.isAway();
      props.network.ircClient.raw('AWAY', val ? '' : 'Currently away');
    }
  },
  isUserSelf: function isUserSelf(props) {
    // let props = this.props;
    if (props.toggle === false) {
      return false;
    }

    var user = (0, _state.default)().getUser(props.network.id, props.network.nick);
    return props.user === user;
  },
  shouldShowStatus: function shouldShowStatus(props) {
    // let props = this.props;
    if (!(0, _state.default)().setting('showAwayStatusIndicators')) {
      return false;
    }

    if (props.network.state !== 'connected') {
      return false;
    }

    var awayNotifyEnabled = props.network.ircClient.network.cap.isEnabled('away-notify');
    return (0, _state.default)().setting('buffers.who_loop') || awayNotifyEnabled;
  }
};
var _default = {
  props: {
    network: Object,
    user: Object,
    toggle: Boolean
  },
  m: methods
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["AwayStatusIndicator"]
window._kiwi_exports.components.AwayStatusIndicator = exports.default ? exports.default : exports;
