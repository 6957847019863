"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderBuffers = orderBuffers;
exports.orderedMessages = orderedMessages;
exports.getNextBuffer = getNextBuffer;
exports.getPreviousBuffer = getPreviousBuffer;

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _findIndex = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find-index"));

var _lodash = _interopRequireDefault(require("lodash"));

var _state = _interopRequireDefault(require("@/libs/state"));

function orderBuffers(buffers) {
  // Since vuejs will sort in-place and update views when .sort is called
  // on an array, clone it first so that we have a plain array to sort
  var list = (0, _map.default)(buffers).call(buffers, function (b) {
    return b;
  });
  list = (0, _filter.default)(_lodash.default).call(_lodash.default, list, function (buffer) {
    return !buffer.isServer();
  });
  list = list.sort(function (a, b) {
    var order = 0;

    if (a.isChannel() && b.isQuery()) {
      order = -1;
    } else if (a.isQuery() && b.isChannel()) {
      order = 1;
    } else {
      order = a.name.localeCompare(b.name);
    }

    return order;
  });
  return list;
}

function orderedMessages(buffer) {
  var _context;

  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var network = buffer.getNetwork();
  var currentNick = network.nick;
  var bufferMessages = buffer.getMessages(); // Hack; We need to make vue aware that we depend on buffer.message_count in order to
  // get the messagelist to update its DOM, as the change of message_count alerts
  // us that the messages have changed. This is done so that vue does not have to make
  // every emssage reactive which gets very expensive.

  /* eslint-disable no-unused-vars */

  var ignoredVar = buffer.message_count;
  var messages = [];

  if (opts.inPlace) {
    messages = bufferMessages;
  } else {
    messages = (0, _slice.default)(bufferMessages).call(bufferMessages, 0, bufferMessages.length);
  }

  messages.sort(function (a, b) {
    if (a.time > b.time) {
      return 1;
    } else if (b.time > a.time) {
      return -1;
    }

    return a.instance_num > b.instance_num ? 1 : -1;
  });

  if (opts.noFilter) {
    return messages;
  }

  var messageTypesToShowSetting = {
    // message.type: 'settings_name'
    traffic: 'show_joinparts',
    topic: 'show_topics',
    nick: 'show_nick_changes',
    mode: 'show_mode_changes',
    presence: 'show_presence_changes'
  };
  var hiddenMessageTypes = (0, _filter.default)(_context = (0, _keys.default)(messageTypesToShowSetting)).call(_context, function (type) {
    return !buffer.setting(messageTypesToShowSetting[type]);
  });
  var list = [];

  for (var i = messages.length - 1; i >= 0; i--) {
    // don't include hidden message types
    if ((0, _includes.default)(hiddenMessageTypes).call(hiddenMessageTypes, messages[i].type)) {
      continue;
    } // Ignored users have the ignore flag set


    if (messages[i].ignore) {
      continue;
    } // Don't show the first connection message. Channels are only interested in
    // the joining message at first. Dis/connection messages are only relevant here
    // if the dis/connection happens between messages (during a conversation)


    if (messages[i].type === 'connection' && i === 0) {
      continue;
    } // When we join a channel the topic is usually sent next. But this looks
    // ugly when rendered. So we switch the topic + join messages around so
    // that the topic is first in the message list.


    if (messages[i].type === 'topic' && messages[i - 1] && messages[i - 1].type === 'traffic' && messages[i - 1].nick === currentNick) {
      list.push(messages[i - 1]);
      list.push(messages[i]);
      i--;
    } else {
      list.push(messages[i]);
    }
  }

  return list.reverse();
}

function getNextBuffer() {
  return getBufferFromDirection(1);
}

function getPreviousBuffer() {
  return getBufferFromDirection(-1);
}

function getBufferFromDirection(direction) {
  var state = (0, _state.default)();
  var network = state.getActiveNetwork();
  var buffer = state.getActiveBuffer();

  if (!network || !buffer) {
    return null;
  }

  var ordered = orderBuffers(network.buffers);
  var index = (0, _findIndex.default)(_lodash.default).call(_lodash.default, ordered, ['name', buffer.name]) + direction;

  if (index >= ordered.length || index < 0) {
    network = getNetworkFromDirection(direction);
    ordered = orderBuffers(network.buffers);
    buffer = direction === 1 ? ordered[0] : ordered[ordered.length - 1];
  } else {
    buffer = ordered[index];
  }

  return buffer;
}

function getNetworkFromDirection(direction) {
  var state = (0, _state.default)();
  var network = state.getActiveNetwork();

  for (var i = 0; i < state.networks.length; i++) {
    var index = (0, _findIndex.default)(_lodash.default).call(_lodash.default, state.networks, ['id', network.id]) + direction;

    if (index >= state.networks.length && state.networks.length >= 0) {
      network = state.networks[0];
    } else if (index < 0) {
      network = state.networks[state.networks.length - 1];
    } else {
      network = state.networks[index];
    }

    if (network.buffers.length > 1) {
      return network;
    }
  }

  return network;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["bufferTools"]
window._kiwi_exports.libs.bufferTools = exports.default ? exports.default : exports;
